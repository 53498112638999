<template>
  <v-row class="fill-height ma-0">
    <NumbersList ref="NumbersList" @loading="e => loading = e" />
   <!--  <v-col v-else-if="!numberData.length && window.width > 575.98"
           cols="12"
           md=""
           lg=""
           class="pa-0 d-flex align-center justify-center grey--text" style="flex:1;border: 1px solid #f5f5f5">
      <p class="grey--text">No spam messages</p>
    </v-col> -->
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="12"
      md=""
      lg="8"
      class="pa-0"
      style="background-color: #f5f5f5; border: 1px solid #f5f5f5"
    >
      <div class="d-flex fill-height">
        <div class="w-100" style="position: relative">
          <v-toolbar v-if="callItem && userProfile.number !== getCallNumber" elevation="0">
            <template>
              <v-toolbar-title v-if="hasCallName && callItem.direction === 'in'" class="pa-0 d-flex flex-column">
                <span class="fz-20">{{ callItem.caller_first_name }} {{ callItem.caller_last_name }}</span>
                <span class="fz-14">{{ formatPhone(getCallNumber) }}</span>
              </v-toolbar-title>
              <v-toolbar-title v-else-if="hasCallName && callItem.direction === 'out'" class="pa-0 d-flex flex-column">
                <span class="fz-20">{{ callItem.callee_first_name }} {{ callItem.callee_last_name }}</span>
                <span class="fz-14">{{ formatPhone(getCallNumber) }}</span>
              </v-toolbar-title>
              <v-toolbar-title v-else class="pa-0">
                <span class="fz-20">{{ formatPhone(getCallNumber) }}</span>
              </v-toolbar-title>
            </template>

            <v-spacer></v-spacer>

            <v-tooltip bottom color="dark" v-if="!callBtnDisabled">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="call">
                  <v-icon>mdi-phone</v-icon>
                </v-btn>
              </template>
              <span>Call</span>
            </v-tooltip>

            <v-tooltip bottom color="dark">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="sendMessage" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-message-reply-text-outline mdi-flip-h</v-icon>
                </v-btn>
              </template>
              <span>Message</span>
            </v-tooltip>

            <ActionsMenu @reload="$refs.NumbersList.loadSpamNumbers(true)" />
          </v-toolbar>
          <v-divider></v-divider>
          <v-card class="fill-height" elevation="0">
            <v-list class="fill-height">
              <v-list-item v-if="callItem">
                <v-list-item-icon color="primary" class="ml-0 mr-3">
                  <v-icon color="red">mdi-alert-octagon</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Suspected spam</v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    {{formatDate(callItem.createdAt)}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="!loading"
                class="fill-height text-center"
                style="background-color:#f5f5f5;border: 1px solid #f5f5f5"
              >
                <v-list-item-content>
                  <v-list-item-title style="font-size:32px">Hi {{userProfile ? userProfile.firstName : ''}}!</v-list-item-title>
                  <v-list-item-subtitle class="mt-4">You’re all caught up</v-list-item-subtitle>
                  <GoogleAdSense adSlot="6685324668" :stylesSm="showPeople" sequence="3" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
        <People v-if="showPeople" @reload="$refs.NumbersList.loadSpamNumbers(true)" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu.vue'
import NumbersList from '@/components/Numbers/NumbersList.vue'
import resizeMixin from '@/mixins/resize.mixin'
import People from '@/views/People'
import { mapState, mapGetters, mapMutations } from 'vuex'
import GoogleAdSense from '../components/GoogleAdSense.vue'
import { formatPhone } from '../utilities/formatPhone'

export default {
  name: 'Spam',
  data: () => ({
    spamNumber: '+65 8384 0660',
    numberData: [],
    loading: false
  }),
  mixins: [resizeMixin],
  components: {
    GoogleAdSense,
    People,
    NumbersList,
    ActionsMenu
  },
  computed: {
    ...mapState(['showPeople']),
    ...mapGetters(['userProfile']),
    ...mapGetters('calls', ['callItem']),
    hasCallName() {
      return (this.callItem.direction === 'in' && (this.callItem.caller_first_name || this.callItem.caller_last_name)) ||
        (this.callItem.direction === 'out' && (this.callItem.callee_first_name || this.callItem.callee_last_name))
    },
    getCallNumber () {
      return this.callItem.direction ? (this.callItem.direction === 'in' ? this.callItem.ani : this.callItem.dnis) : this.callItem.number
    },
    isFreeAccount() {
      return this.userProfile ? this.userProfile.is_free_user : true
    },
    isAccountUnderTheCompany() {
      return this.userProfile ? !!this.userProfile.company_domain : false
    },
    callBtnDisabled () {
      return this.isAccountUnderTheCompany ? false : this.isFreeAccount
    }
  },
  created() {
    // this.getSpamList()
    this.$store.commit('actionMenuNumber', this.spamNumber)
  },
  methods: {
    ...mapMutations('sip', ['changePhoneNumber']),
    formatPhone,
    getPhoneNumber() {
      if (isNaN(+this.getCallNumber)) {
        return this.getCallNumber === this.callItem.ani ? this.callItem.dnis : this.callItem.ani
      }
      return this.getCallNumber
    },
    call() {
      if (!this.userProfile || this.callBtnDisabled) {
        return
      }
      this.changePhoneNumber(this.getPhoneNumber())
    },
    sendMessage () {
      this.$router.push({ name: 'Messages', query: { n: this.getPhoneNumber() } })
    }
    /* ...mapActions('numbers', ['getSpamNumbers']),
    async getSpamList() {
      try {
        const data = await this.getSpamNumbers()
        this.numberData = data
      } catch (err) {
        throw new Error(err)
      }
    } */
  }
}
</script>
