<template>
  <v-col cols="12" md="auto" lg="4" sm="12" class="pa-0 d-flex numbers-list" @scroll="handleScroll">
    <v-card
      :loading="loading && skip === 0"
      class="w-100 numbers-list-calls"
    >
<!--      <GoogleAdSense show-ads3 />-->
<!--      <div v-if="!showAdBottom" style="min-width:220px;max-width:970px;width:100%;min-height:200px;max-height:200px;height:100%; background-color: #2F80ED;"></div>-->
      <v-list v-if="showCalls" class="py-0 numbers-list-calls-list">
        <v-list-item
          v-for="(item, index) in calls"
          :key="index"
          :class="{ 'numbers-list-calls-list-item--active': callItem && callItem.uuid === item.uuid }"
          class="numbers-list__item calls-item"
          @click="toggleCallItem(item, !isDesktop && callItem && callItem.uuid === item.uuid)"
        >
          <v-list-item-avatar color="primary" class="ml-3 ml-md-0">
            <v-icon color="#fff">mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="call-name d-flex align-center justify-space-between">
              <span class="font-weight-regular">{{ getCallName(item) }}</span>
              <span class="fz-12 mt-1" v-if="$vuetify.breakpoint.mdAndUp">{{ item.duration }}s</span>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item" class="px-0 d-flex align-center">
              <template v-if="isDesktop">
                <div>
                  <v-icon v-if="item.is_spam || $route.name === 'Spam'" color="red" size="16px">mdi-call-missed</v-icon>
                  <v-icon v-else-if="item.direction === 'out'" color="red" size="16px">mdi-call-made</v-icon>
                  <v-icon v-else color="green" size="16px">mdi-call-received</v-icon>
                </div>
                <span v-if="item.is_spam" class="ml-1 red--text fz-12">Suspected spam<span class="text--darken-2 grey--text"> • {{ formatDateShort(item.createdAt) }}</span></span>
                <span v-else class="ml-1 fz-12">{{ formatDate(item.createdAt) }}</span>
              </template>
              <span v-else>
                {{ item.direction === 'out' ? item.dnis : item.ani }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="item && callItem && callItem.uuid === item.uuid && !isDesktop" class="ml-0">
            <div class="w-100 pl-11 pb-3 fz-14 grey--text border-bottom">
              <div v-if="item.is_spam || $route.name === 'Spam'">
                <v-icon color="red" size="16px">mdi-call-missed</v-icon>
                <span class="ml-2">Suspected spam</span>
              </div>
              <div v-else-if="item.direction === 'out'">
                <v-icon color="red" size="16px">mdi-call-made</v-icon>
                <span class="ml-2">Outgoing call</span>
              </div>
              <div v-else>
                <v-icon color="green" size="16px">mdi-call-received</v-icon>
                <span class="ml-2">Incoming call</span>
              </div>
              <div class="ml-6">{{ formatDate(item.createdAt) }}</div>
              <div class="ml-6">
                <span>Call duration: </span>
                <span>{{ item.duration }}s</span>
              </div>
            </div>
            <v-list-item-icon class="call-phone-icon">
              <a :href="'tel:' + item.ani" style="text-decoration: none">
                <v-icon>mdi-phone</v-icon>
                Call {{ getCallName(item) }}
              </a>
            </v-list-item-icon>
            <v-list-item-icon class="message-icon">
              <a :href="'mailto:'" style="text-decoration: none">
                <v-icon>mdi-message-reply-text-outline</v-icon>
                Message {{ getCallName(item) }}
              </a>
            </v-list-item-icon>
          </v-list-item-action>

        </v-list-item>
      </v-list>
      <v-list v-else-if="showVoicemails" class="py-0 w-100">
        <v-list-item
          v-for="(item, index) in voiceItems"
          :key="index"
          :class="{ 'v-list-item--active': voicemailItem && voicemailItem.uuid === item.uuid }"
          class="numbers-list__item voicemails"
          @click="toggleVoicemailItem(item, !isDesktop && voicemailItem && voicemailItem.uuid === item.uuid)"
        >
          <v-list-item-avatar color="primary">
            <v-icon color="#fff">mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-no-wrap" :class="[!item.is_read && 'font-weight-bold']">
              {{ getCallName(item) }}
            </v-list-item-title>
            <v-list-item-subtitle class="px-0 d-flex align-center overflow-hidden mt-1">
              <template v-if="item.transcripted_text">
                <v-icon small>mdi-voicemail</v-icon>
                <span class="ml-1 fz-12 text-no-wrap text-ellipse" :class="[!item.is_read && 'font-weight-bold']">{{ item.transcripted_text.substr(0, 20) }}</span>
              </template>
              <span class="ml-1 fz-12 text-no-wrap text-ellipse" v-else>Transcription not available</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="voicemailItem && voicemailItem.uuid === item.uuid && !isDesktop">
            <div class="d-flex align-center w-100">
              <v-btn icon large @click.stop.prevent="togglePlay">
                <v-icon>{{ paused ? 'mdi-play' : 'mdi-pause' }}</v-icon>
              </v-btn>
              <div style="width: 100%">
                <v-slider
                  @end="changeTimePoint"
                  @mousedown.stop.prevent="barIsClicked = true"
                  @mouseup.stop.prevent="barIsClicked = false"
                  v-model="time.percentage"
                  class="slider"
                ></v-slider>
                <v-layout justify-space-between class="mt-1">
                  <span class="caption">{{formatTime(time.current)}}</span>
                  <span class="caption">{{formatTime(time.total)}}</span>
                </v-layout>
              </div>
            </div>
            <div v-if="item.description" class="description">{{ item.description }}</div>
            <v-list-item-icon class="call-phone-icon">
              <a :href="'tel:' + item.ani" style="text-decoration: none">
                <v-icon>mdi-phone</v-icon>
                Call {{ formatPhone(item.direction === 'out' ? item.dnis : item.ani) }}
              </a>
            </v-list-item-icon>
            <v-list-item-icon class="message-icon">
              <a :href="'mailto:'" style="text-decoration: none">
                <v-icon>mdi-message-reply-text-outline</v-icon>
                Message {{ formatPhone(item.direction === 'out' ? item.dnis : item.ani) }}
              </a>
            </v-list-item-icon>
          </v-list-item-action>
          <!-- <v-list-item-icon v-else class="call-phone-icon">
            <a :href="'tel:' + item.title" style="text-decoration: none">
              <v-icon>mdi-phone</v-icon>
            </a>
          </v-list-item-icon> -->
          <div v-if="isDesktop" class="d-flex flex-column align-end date">
            <span>{{ formatDate(item.createdAt) }}</span>
            <span>{{ formatTime(item.duration) }}</span>
          </div>
          <ActionsMenu v-else class="numbers-list__dot" />
        </v-list-item>
      </v-list>
      <v-list v-else-if="$route.name === 'Messages'" class="fill-height">
        <v-list-item
          :style="isDesktop ? '' : 'font-weight:500; color:#5F6368'"
          class="numbers-list__item voicemails"
          @click="sendNewMessage"
        >
          <v-icon class="mr-3" color="#27AE60">mdi-plus</v-icon> <!-- #27AE60 #BDBDBD -->
          Send new message
        </v-list-item>
        <v-divider/>
        <template v-if="showMessages">
          <v-list-item
            v-for="item in listMessages"
            :key="item.num"
            :class="{ 'v-list-item--active': messageItem && messageItem.num === item.num }"
            class="numbers-list__item voicemails"
            style="align-items: start!important;"
            @click="toggleMessageItem(item, !isDesktop && messageItem && messageItem.num === item.num)"
          >
            <v-list-item-avatar color="primary">
              <v-icon color="#fff">mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-no-wrap" :class="{ 'font-weight-bold': item.direction === 'in' && !item.is_read }">
                {{ getDnisMessageName(item) }}
              </v-list-item-title>
              <v-list-item-subtitle class="px-0 d-flex align-center overflow-hidden">
                <div>
                  <v-icon v-if="item.is_spam || $route.name === 'Spam'" color="red" size="16px">mdi-call-missed</v-icon>
                  <v-icon v-else-if="item.direction === 'out'" color="red" size="16px">mdi-call-made</v-icon>
                  <v-icon v-else color="green" size="16px">mdi-call-received</v-icon>
                </div>
                <span class="ml-1 fz-12 text-no-wrap text-ellipse" :class="{ 'font-weight-bold': item.direction === 'in' && !item.is_read }">{{ item.text.substr(0, 20) }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="messageItem && messageItem.uuid === item.uuid && !isDesktop">
              <v-list-item-icon class="call-phone-icon">
                <a :href="'tel:' + item.ani" style="text-decoration: none">
                  <v-icon>mdi-phone</v-icon>
                  Call {{ formatPhone(item.direction === 'out' ? item.dnis : item.ani) }}
                </a>
              </v-list-item-icon>
              <v-list-item-icon class="message-icon">
                <a
                  style="text-decoration: none"
                  @click="$router.push({ name: 'Messages', query: { n: item.direction === 'out' ? item.dnis : item.ani } })"
                >
                  <v-icon>mdi-message-reply-text-outline</v-icon>
                  Message {{ formatPhone(item.direction === 'out' ? item.dnis : item.ani) }}
                </a>
              </v-list-item-icon>
            </v-list-item-action>
            <div v-if="isDesktop" class="d-flex flex-column align-end date mt-3">
              <span>{{ formatDate(('sent_on' in item || 'receive_on' in item) ? item[item.direction === 'out' ? 'sent_on' : 'receive_on'] : (item.created_at || item.createdAt), 'MMM DD, YYYY') }}</span>
            </div>
            <ActionsMenu v-else class="numbers-list__dot" />
          </v-list-item>
        </template>
        <v-list-item v-else class="fill-height d-flex flex-column justify-center" style="max-height:calc(100vh - 135px)">
          <p class="text-center w-100 ma-0">No Messages</p>
          <GoogleAdSense adSlot="5078078306" styles="display:inline-block;min-width:220px;max-width:360px;width:100%;min-height:800px;max-height:800px;height:100%" sequence="3" />
        </v-list-item>
      </v-list>
      <v-list v-else-if="showSpam" class="py-0 numbers-list-calls-list">
        <v-list-item
          v-for="(item, index) in spamNumbers"
          :key="index"
          :class="{ 'numbers-list-calls-list-item--active': callItem && callItem.uuid === item.uuid }"
          class="numbers-list__item calls-item"
          @click="toggleCallItem(item, !isDesktop && callItem && callItem.uuid === item.uuid)"
        >
          <v-list-item-avatar color="primary" class="ml-3 ml-md-0">
            <v-icon color="#fff">mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="call-name d-flex align-center justify-space-between">
              <span class="font-weight-regular">{{ getCallName(item) }}</span>
              <span class="fz-12 mt-1" v-if="$vuetify.breakpoint.mdAndUp && item.duration">{{ item.duration }}s</span>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item" class="px-0 d-flex align-center">
              <template v-if="isDesktop">
                <div>
                  <v-icon v-if="item.is_spam || $route.name === 'Spam'" color="red" size="16px">mdi-call-missed</v-icon>
                  <v-icon v-else-if="item.direction === 'out'" color="red" size="16px">mdi-call-made</v-icon>
                  <v-icon v-else color="green" size="16px">mdi-call-received</v-icon>
                </div>
                <span v-if="item.is_spam" class="ml-1 red--text fz-12">Suspected spam<span class="text--darken-2 grey--text"> • {{ formatDateShort(item.createdAt) }}</span></span>
                <span v-else class="ml-1 fz-12">{{ formatDate(item.createdAt) }}</span>
              </template>
              <span v-else>
                {{ item.direction === 'out' ? item.dnis : item.ani }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="item && callItem && callItem.uuid === item.uuid && !isDesktop" class="ml-0">
            <div class="w-100 pl-11 pb-3 fz-14 grey--text border-bottom">
              <div v-if="item.is_spam || $route.name === 'Spam'">
                <v-icon color="red" size="16px">mdi-call-missed</v-icon>
                <span class="ml-2">Suspected spam</span>
              </div>
              <div v-else-if="item.direction === 'out'">
                <v-icon color="red" size="16px">mdi-call-made</v-icon>
                <span class="ml-2">Outgoing call</span>
              </div>
              <div v-else>
                <v-icon color="green" size="16px">mdi-call-received</v-icon>
                <span class="ml-2">Incoming call</span>
              </div>
              <div class="ml-6">{{ formatDate(item.createdAt) }}</div>
              <div class="ml-6">
                <span>Call duration: </span>
                <span>{{ item.duration }}s</span>
              </div>
            </div>
            <v-list-item-icon class="call-phone-icon">
              <a :href="'tel:' + item.ani" style="text-decoration: none">
                <v-icon>mdi-phone</v-icon>
                Call {{ getCallName(item) }}
              </a>
            </v-list-item-icon>
            <v-list-item-icon class="message-icon">
              <a :href="'mailto:'" style="text-decoration: none">
                <v-icon>mdi-message-reply-text-outline</v-icon>
                Message {{ getCallName(item) }}
              </a>
            </v-list-item-icon>
          </v-list-item-action>

        </v-list-item>
      </v-list>
      <v-list
        v-else-if="!loading && (
          $route.name === 'Voicemails' && !voiceItems.length ||
          $route.name === 'Messages' && !listMessages.length ||
          !calls.length)"
        class="py-0 numbers-list-calls-list fill-height"
      >
        <v-list-item class="fill-height d-flex flex-column justify-center">
          <p class="text-center w-100 ma-0">No {{$route.name}}</p>
          <GoogleAdSense adSlot="5078078306" styles="display:inline-block;min-width:220px;max-width:360px;width:100%;min-height:800px;max-height:800px;height:100%" sequence="3" />
        </v-list-item>
      </v-list>
      <GoogleAdSense adSlot="5016129435" styles="display:inline-block;min-width:220px;max-width:360px;width:100%;min-height:800px;max-height:800px;height:100%" sequence="3" />
      <GoogleAdSense adSlot="4381798790" styles="display:inline-block;min-width:220px;max-width:360px;width:100%;min-height:400px;max-height:500px;height:100%" sequence="4" />
<!--      <div v-if="showAdBottom" style="min-width:220px;max-width:970px;width:100%;min-height:200px;max-height:200px;height:100%; background-color: #2F80ED;"></div>-->
      <v-progress-linear v-if="loading && skip > 0" indeterminate rounded />
    </v-card>
    <audio
      v-if="!isDesktop"
      :src="source"
      ref="audio"
      @play="onPlay"
      @pause="onPause"
      @ended="ended"
      @timeupdate="updateTime"
      @durationchange="onDurationChange"
    ></audio>
  </v-col>
</template>

<script>
import resizeMixin from '@/mixins/resize.mixin'
import ActionsMenu from '@/components/ActionsMenu.vue'
import moment from 'moment'

import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getVoicemailStorage, removeVoicemailStorage } from '../../utilities/voicemailHelper'
import GoogleAdSense from '../GoogleAdSense.vue'
import { formatPhone } from '../../utilities/formatPhone'

export default {
  name: 'NumbersList',
  components: {
    GoogleAdSense,
    ActionsMenu
  },
  mixins: [resizeMixin],
  data: () => {
    return {
      sheet: false,
      isActive: null,
      show: false,
      items: [],
      loading: false,
      loadingSpam: false,
      calls: [],
      voiceItems: [],
      archiveData: [],
      // messages: [],
      total: 0,
      take: 20,
      skip: 0,
      spamNumbers: [],
      barIsClicked: false,
      paused: true,
      time: {
        total: 0,
        current: 0,
        interval: null,
        percentage: 0
      },
      source: ''
    }
  },
  computed: {
    ...mapGetters('calls', ['callItem', 'voicemailItem']),
    ...mapGetters('messages', ['messageItem', 'smsList', 'listMessages']),
    ...mapGetters('contacts', ['getFullNameByNumber']),
    ...mapGetters(['searchText']),
    totalMessageList () {
      return this.$store.state.messages.total
    },
    showCalls () {
      return this.$route.name !== 'Voicemails' && this.$route.name !== 'Messages' && this.$route.name !== 'Spam' && (!this.loading || this.skip > 0) && this.calls.length > 0
    },
    showMessages () {
      return this.$route.name === 'Messages' && (!this.loading || this.skip > 0) && this.listMessages.length > 0
    },
    showVoicemails () {
      return this.$route.name === 'Voicemails' && (!this.loading || this.skip > 0) && this.voiceItems.length > 0
    },
    showSpam () {
      return this.$route.name === 'Spam' && (!this.loadingSpam || this.skip > 0) && this.spamNumbers.length > 0
    },
    isDesktop () {
      return this.$vuetify.breakpoint.smAndUp
    },
    wssVoicemails () {
      return this.$store.state.calls.voicemails
    },
    messageNewContactCounter () {
      return this.$store.state.messages.newContactCounter
    },
    wssCalls () {
      return this.$store.state.calls.calls
    },
    routeQuery () {
      return this.$route.query
    }
  },
  watch: {
    routeQuery (val) {
      if (this.$route.name === 'Messages') {
        this.setMessageItem({ num: val.n })
      }
    },
    isDesktop (val) {
      if (this.showCalls) {
        this.setCallItem(val ? this.calls[0] : null)
      } else if (this.showVoicemails) {
        this.setVoicemailItem(val ? this.voiceItems[0] : null)
      }
    },
    outboundCallStatus (status) {
      if (status === 'answered' || !status) {
        this.timeoutLoadNewLastCall()
      }
    },
    inboundCallsLength () {
      this.timeoutLoadNewLastCall()
    },
    wssVoicemails (newValue) {
      if (this.$route.name === 'Voicemails') {
        const lastItem = newValue.length > 1 ? newValue[newValue.length - 1] : newValue[0]
        if (lastItem) {
          if (!lastItem.createdAt) {
            this.silentLoadVoicemails()
            return
          }
          const isExist = this.voiceItems.findIndex(item => item.uuid === lastItem.uuid)
          if (isExist !== -1) {
            this.$set(this.voiceItems, isExist, {
              ...this.voiceItems[isExist],
              ...lastItem
            })
          } else {
            this.voiceItems.unshift(lastItem)
            this.total++
          }
        }
      }
    },
    messageNewContactCounter () {
      this.silentLoadMessages()
    },
    wssCalls (value) {
      if (this.$route.name === 'Calls') {
        const lastItem = value.length > 1 ? value[value.length - 1] : value[0]
        if (lastItem) {
          const isExist = this.calls.findIndex(item => item.call_uuid === lastItem.call_uuid)
          if (lastItem.direction === 'in' && isExist === -1 && ['hangup', 'offline'].includes(lastItem.state)) {
            this.loadNewLastCall(lastItem)
            return
          }
          if (lastItem.direction === 'in' && !lastItem.createdAt && !['hangup', 'offline'].includes(lastItem.state)) return
          if (isExist !== -1) {
            this.$set(this.calls, isExist, {
              ...this.calls[isExist],
              ...lastItem
            })
          } else {
            this.calls.unshift(lastItem)
            this.total++
          }
        }
      }
    },
    voicemailItem: {
      deep: true,
      handler () {
        this.setPlayerData()
      }
    },
    searchText () {
      this.reloadNumberList()
    }
  },
  created() {
    // this.setVoicemailItem(this.voiceItems[0])
    this.$store.dispatch('showPeopleAction', false)
    this.getLabels()
    this.getContacts()
    this.loadVoicemails()
    this.loadMessages()
    this.reloadNumberList()
    this.setPlayerData()
    if (this.$route.name === 'Spam') this.loadSpamNumbers()
  },
  beforeDestroy() {
    this.removeCalls()
  },
  methods: {
    ...mapMutations('calls', ['setCallItem', 'setVoicemailItem', 'removeCalls', 'setCalls']),
    ...mapMutations('messages', ['setMessageItem', 'addListMessages', 'setTotal', 'updateListMessages', 'setUnreadCount']),
    ...mapMutations(['changeMessageTo']),
    ...mapActions('messages', ['markSmsAsRead', 'getUnreadCount']),
    ...mapActions('calls', ['getCalls', 'markVoicemailAsRead']),
    ...mapActions(['getVoicemailsList', 'getChatList']),
    ...mapActions('contacts', ['getContacts']),
    ...mapActions('labels', ['getLabels']),
    ...mapActions('numbers', ['getSpamNumbers']),
    formatPhone,
    getCallName(item) {
      if (item.direction === 'in' || !item.direction) {
        return !item.caller_first_name && !item.caller_last_name ? this.formatPhone(item.ani || item.number) : `${item.caller_first_name ? item.caller_first_name : ''} ${item.caller_last_name ? item.caller_last_name : ''}`
      } else {
        const callerFirst = item.callee_first_name
        const callerLast = item.callee_last_name
        if (callerFirst || callerLast) return `${callerFirst || ''} ${callerLast || ''}`.trim()
        return this.formatPhone(item.dnis)
      }
    },
    getDnisMessageName(item) {
      const temp = this.smsList.find(e => e.sms_uuid === item.sms_uuid)
      if (temp) {
        item = {
          ...temp,
          ...item
        }
      }
      const first = item.direction === 'in' ? item.caller_first_name : item.callee_first_name
      const last = item.direction === 'in' ? item.caller_last_name : item.callee_last_name
      if (first || last) return `${first || ''} ${last || ''}`.trim()
      return this.formatPhone(item.num)
    },
    async loadNewLastCall (lastItem) {
      if (this.$route.name !== 'Calls') {
        return
      }
      try {
        const params = {
          orderType: 'DESC',
          orderBy: 'createdAt',
          take: 100,
          skip: 0
        }
        const data = await this.getCalls({ dir: 'in', params })
        const item = data.data.find(item => item.call_uuid === lastItem.call_uuid)
        const isExist = this.calls.findIndex(call => call.call_uuid === item.call_uuid)
        if (isExist !== -1) {
          this.$set(this.calls, isExist, item)
          return
        }
        if (item) {
          this.calls.unshift(item)
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    async reloadNumberList (reload = false) {
      if (this.$route.name !== 'Calls' && this.$route.name !== 'Archive') return // && this.$route.name !== 'Spam'
      try {
        this.loading = true

        if (!this.skip) {
          this.$emit('loading', true)
        }

        if (reload) {
          this.setCallItem(null)
        }

        let params = {
          orderType: 'DESC',
          orderBy: 'createdAt',
          take: reload ? this.skip + this.take : this.take,
          skip: reload ? 0 : this.skip,
          is_spam: false,
          is_archive: false
        }

        if (this.$route.name === 'Archive') {
          params = {
            ...params,
            is_archive: true
          }
        }/*  else if (this.$route.name === 'Spam') {
          params = {
            ...params,
            is_spam: true
          }
        } */

        let data = {
          data: [],
          total: 0
        }

        if (this.searchText) {
          const res1 = await this.getCalls({ dir: 'all', params: { ...params, caller_id: this.searchText } })
          const res2 = await this.getCalls({ dir: 'all', params: { ...params, phone_number: this.searchText } })
          data.data = [...res1.data, ...res2.data]
          data.total = +res1.total + +res2.total
        } else {
          data = await this.getCalls({ dir: 'all', params })
        }

        if (this.skip > 0 && !reload) {
          // return this.calls.push(...(this.$route.name !== 'Spam' ? data.data : data.data.map(item => ({ ...item, direction: 'in' }))))
          return this.calls.push(...data.data)
        }

        this.total = data.total
        // if (this.$route.name !== 'Spam') {
        this.calls = data.data
        // } else {
        //   this.calls = data.data.map(item => ({ ...item, direction: 'in' }))
        // }

        if (this.isDesktop && this.calls.length && !this.$route.params.redirect) this.setCallItem(this.calls[0])
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
        this.$emit('loading', false)
      }
    },
    async silentLoadVoicemails () {
      if (this.$route.name !== 'Voicemails') return
      try {
        const params = {
          orderType: 'DESC',
          orderBy: 'createdAt',
          take: this.take,
          skip: 0
        }
        const data = await this.getVoicemailsList(params)
        removeVoicemailStorage()

        this.voiceItems = data.data
        this.total = data.total
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadVoicemails (reload = false) {
      if (this.$route.name !== 'Voicemails') return
      try {
        this.loading = true
        if (!this.skip) {
          this.$emit('loading', true)
        }
        const params = {
          orderType: 'DESC',
          orderBy: 'createdAt',
          take: reload ? this.skip + this.take : this.take,
          skip: reload ? 0 : this.skip
        }
        const data = await this.getVoicemailsList(params)
        const voicemailUuidFormEmail = getVoicemailStorage()
        removeVoicemailStorage()

        if (this.skip > 0 && !reload) return this.voiceItems.push(...data.data)

        this.voiceItems = data.data
        this.total = data.total
        if (!this.skip && this.isDesktop && this.voiceItems.length) this.setVoicemailItem(this.voiceItems[0])
        if (voicemailUuidFormEmail) {
          const itemFormVoiceList = data.data.find(item => item.uuid === voicemailUuidFormEmail)
          itemFormVoiceList && this.setVoicemailItem(itemFormVoiceList)
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
        this.$emit('loading', false)
      }
    },
    async silentLoadMessages () {
      if (this.$route.name !== 'Messages') return
      try {
        const params = {
          take: this.take,
          skip: 0
        }
        const data = await this.getChatList(params)
        this.updateListMessages(data.data)
        this.setTotal(data.total)
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadMessages (reload = false) {
      if (this.$route.name !== 'Messages') return
      try {
        this.loading = true
        if (!this.skip) {
          this.$emit('loading', true)
        }
        const params = {
          take: reload ? this.skip + this.take : this.take,
          skip: reload ? 0 : this.skip
        }
        const data = await this.getChatList(params)
        // const unreadCount = data.data.filter(item => !item.is_read && item.direction === 'in').length
        // this.setUnreadCount(unreadCount)
        await this.getUnreadCount()
        if (this.$route.query.n) {
          const _messageItem = data.data.find(item => item.num === this.$route.query.n)
          if (_messageItem) this.setMessageItem(_messageItem)
        }
        if (this.skip > 0 && !reload) return this.addListMessages(data.data)

        this.updateListMessages(data.data)
        this.setTotal(data.total)
        // this.total = data.total
        if (!this.skip && this.isDesktop && !this.$route.query.n) this.setMessageItem(this.listMessages[0])
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
        this.$emit('loading', false)
      }
    },
    async readSms (item) {
      if (!item || !item?.sms_uuid || item.is_read) return
      try {
        const res = await this.markSmsAsRead(item.num)
        if (res.success) {
          this.$store.commit('messages/updateListMessage', {
            number: item.num,
            message: {
              ...item,
              is_read: true
            }
          })
          this.$store.commit('messages/updateUnreadCount', { action: 'minus' })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    async readVoicemail (item) {
      if (!item || item?.is_read || !item?.uuid) return
      try {
        const res = await this.markVoicemailAsRead(item.uuid)
        if (res.success) {
          this.voiceItems.find(e => e.uuid === item.uuid).is_read = true
          this.$store.commit('calls/updateUnreadCount', { type: 'voicemails', action: 'minus' })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadSpamNumbers (reload = false) {
      try {
        this.loadingSpam = true
        const data = await this.getSpamNumbers()
        if (!data) return
        this.spamNumbers = data
        if (reload) {
          this.setCallItem(null)
        }
        console.log(this.spamNumbers, 'spamNumbers')
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loadingSpam = false
      }
    },
    toggleCallItem (item, reset) {
      this.setCallItem(reset ? null : item)
    },
    toggleVoicemailItem (item, reset) {
      !item.is_read && this.readVoicemail(item)
      this.setVoicemailItem(reset ? null : item)
    },
    toggleMessageItem (item, reset) {
      if (item.direction === 'in' && !item.is_read) this.readSms(item)
      this.setMessageItem(reset ? null : item)
    },
    /* inSpam (item) {
      return item.direction === 'in' && this.spamNumbers.findIndex(e => e.number === item.ani) >= 0
    }, */
    sendNewMessage () {
      this.changeMessageTo(true)
      this.setMessageItem({ to: '' })
      if (this.$route.name !== 'Messages') {
        this.$router.replace('/messages')
      }
    },
    async handleScroll (e) {
      if (this.loading || this.skip + this.take > (this.$route.name === 'Messages' ? this.totalMessageList : this.total)) return
      const hasScrolledToBottom = (e.target.offsetHeight + e.target.scrollTop) >= e.target.scrollHeight
      if (hasScrolledToBottom) {
        this.skip += this.take
        if (this.showVoicemails) await this.loadVoicemails()
        if (this.showMessages) await this.loadMessages()
        if (this.showCalls) await this.reloadNumberList()
      }
    },
    formatTime (time) {
      time = parseInt(time)
      const m = parseInt(time / 60)
      const s = time % 60
      return `${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
    },
    setPlayerData () {
      if (!this.voicemailItem) return
      this.time.percentage = 0
      this.paused = true
      this.source = this.voicemailItem.b_leg_record_url
      // this.source = this.voicemailItem.direction === 'in' ? this.voicemailItem.a_leg_record_url : this.voicemailItem.b_leg_record_url
      // this.time.total = this.$refs.audio.duration
    },
    onPlay () {
      this.paused = false
      this.updateTime()
    },
    onPause () {
      this.paused = true
      // clearInterval(this.time.interval)
    },
    updateTime () {
      const currentTime = this.$refs.audio?.currentTime
      const duration = this.$refs.audio.duration
      const percentage = (currentTime / duration) * 100
      this.time.current = currentTime
      if (!this.barIsClicked) {
        this.time.percentage = percentage
      }
    },
    changeTimePoint (event) {
      if (typeof event !== 'number') return
      const d = this.$refs.audio.duration
      this.$refs.audio.currentTime = (d * event) / 100
    },
    onDurationChange (e) {
      this.time.total = e.target.duration
    },
    togglePlay () {
      if (this.paused) {
        this.time.current = 0
        this.time.percentage = 0
        this.$refs.audio.play()
        this.paused = false
        return
      }
      this.ended()
    },
    ended () {
      this.paused = true
      // this.time.percentage = 0
      // this.time.current = 0
      this.$refs.audio.pause()
    }
  }
}
</script>

<style scoped lang="scss">
.date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #828282;
}

.calls-item {
  cursor: pointer;
}

.calls-item::after {
  content: none;
}

.calls-item:hover {
  background: #5758B020;
}

.numbers-list {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 72px);
}

.numbers-list::-webkit-scrollbar{
  display: none;
}

::v-deep .v-progress-linear {
  border-radius: 0 0 2vw 2vw !important;
}

.numbers-list-calls {
  box-shadow: none !important;
  &-list-item--active {
    background: #e2e2e2;
    /*box-shadow: 0 0 2px 2px #82828230;*/
  }
}

.border-bottom {
  border-bottom: 1px solid #E3E3E3;
}

.text-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 325px) {
  .numbers-list__item {
    max-width: 290px;
  }
}
</style>
